import React from 'react';

const Suspensed = (Element) =>
    function suspense(props) {
        return (
            <React.Suspense fallback={<div />}>
                <Element {...props} />
            </React.Suspense>
        );
    };

export default {
    HomePage: Suspensed(React.lazy(() => import('./HomePage'))),
    AboutPage: Suspensed(React.lazy(() => import('./AboutPage'))),
    ArticlePage: Suspensed(React.lazy(() => import('./ArticlePage'))),
    DetailArticlePage: Suspensed(
        React.lazy(() => import('../fragments/detailArticle')),
    ),
    ContactPage: Suspensed(React.lazy(() => import('./ContactPage'))),
    ServicePage: Suspensed(React.lazy(() => import('./ServicePage'))),
    DetailServicePage: Suspensed(
        React.lazy(() => import('../fragments/detailService')),
    ),
};
