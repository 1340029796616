import React from 'react';
import { render } from 'react-dom';
import App, { default as NewApp } from './App';
import { BrowserRouter } from 'react-router-dom';
import './App.css';

render(<App />, document.getElementById('root'));

moduleHotAccept(module);

export function moduleHotAccept(mod) {
    if (mod.hot) {
        mod.hot.accept('./App', () => {
            render(
                <BrowserRouter>
                    <NewApp />
                </BrowserRouter>,
                document.getElementById('root'),
            );
        });
    }
}
