import React from 'react';
import { hot } from 'react-hot-loader/root';
import {
    BrowserRouter,
    BrowserRouter as Router,
    Route,
    Routes,
} from 'react-router-dom';
import pages from './pages';

const App = () => (
    <BrowserRouter>
        <Routes>
            <Route element={<pages.HomePage />} exact path="/" />
            <Route element={<pages.AboutPage />} exact path="/tentang-kami" />
            <Route element={<pages.ArticlePage />} exact path="/artikel" />
            <Route
                element={<pages.DetailArticlePage />}
                exact
                path="/artikel/:title"
            />
            <Route element={<pages.ContactPage />} exact path="/hubungi-kami" />
            <Route element={<pages.ServicePage />} exact path="/layanan-kami" />
            <Route
                element={<pages.DetailServicePage />}
                exact
                path="/layanan-kami/:title"
            />
        </Routes>
    </BrowserRouter>
);

export default hot(App);
